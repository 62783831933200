import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";

export default function privacy() {
  return (
    <Layout>
      <Helmet>
        <title>Chopstick Express | Privacy Policy</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Bored? Read the Privacy Policy of Chopstick Express after you enjoy the best Chinese food in St. Petersburg, FL."
        />
        <link rel="canonical" href="https://www.chopstickfl.com/privacy" />
      </Helmet>
      <div
        style={{
          backgroundColor: "rgb(252,252,252)",
          textAlign: "left",
          marginBottom: -8
        }}
      >
        <br />
        <div style={{ width: "84%", margin: "auto" }}>
          <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>

          <p>
            <b>FoodWeb LLC.</b> ('Us', 'We', 'Our') operates the
            www.chopstickfl.com website (the "Service").
          </p>

          <p>
            This page informs you of our policies regarding the collection and
            use of personal data when you use the Service.
          </p>

          <p>
            Your data is used to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy.
          </p>

          <p>
            By using the Service, you consent to the collection and use of your
            information as we will outline in this policy
          </p>

          <h3>Information Collection And Use</h3>

          <p>
            Several types of information is collected for various purposes to
            provide the Service to you. We do not collect any unique information
            about you except the information that you specifically and knowingly
            provide.
          </p>

          <h3>Types of Data Collected</h3>

          <h4>Personal Data</h4>

          <p>
            While using the Service, you may be asked to provide certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>

          <ol>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address and ZIP/Postal code</li>
            <li>Payment Method and Information</li>
          </ol>

          <h3>Use of Data</h3>

          <p>The collected data for various purposes:</p>
          <ol>
            <li>To provide the Service</li>
            <li>
              To allow you to participate in interactive features of the Service
              when you choose to do so
            </li>
            <li>To provide customer care and support</li>
            <li>
              To provide analysis or valuable information so that we can improve
              the Service
            </li>
            <li>To monitor the usage of the Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ol>

          <h3>Transfer Of Data</h3>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            We will take all steps reasonably necessary to ensure that your data
            is treated securely and in accordance with this Privacy Policy and
            no transfer of your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including
            the security of your data and other personal information.
          </p>

          <h3>Disclosure Of Data</h3>
          <h4>Operating the Service</h4>
          <p>
            Information about you is shared with Chopstick Express Inc in order
            to provide them the necessary information about your order(s).{" "}
          </p>
          <h4>Legal Requirements</h4>
          <p>
            Your Personal Data may be disclosed in the good faith belief that
            such action is necessary to:
          </p>
          <ol>
            <li>To comply with a legal obligation</li>
            <li>
              To protect and defend the rights or property of Chopstick Express
              Inc.
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ol>

          <h3>Security Of Data</h3>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h3>Service Providers</h3>
          <p>
            Third party companies and individuals may be employed to facilitate
            our Service ("Service Providers"), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <h3>Children's Privacy</h3>
          <p>
            The Service does not address anyone under the age of 18
            ("Children").
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected
            Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>

          <h3>Changes To This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
        <br />
      </div>
    </Layout>
  );
}
